.search-form {
  display: flex;
  align-items: center; /* Align items vertically */
  width: 50%;
  margin: 0 25% 0 25%;
}

.search-form input {
  padding: 10px;
  font-size: 15px;
  margin-right: 5px; /* Add some spacing between input and button */
}

@media screen and (max-width: 1000px) {
  .search-form {
    width: 90%;
    margin: 0 5% 0 5%;
  }
}