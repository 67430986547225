.slider {
  cursor: pointer;
}

.slider-img {
  display: block;
  width: 90%;
  height: 200px; /* Set a fixed height */
  object-fit: contain; /* Ensure the entire image is visible within the fixed height */
  background-color: white;
  padding-bottom: 2%; /* Adjust padding as needed */
  border-radius: 10px;
  margin: 5% 0% 0% 5%;
}

.slider-products {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 5%;
  border-radius: 10px;
  background-color: white;
}

.slider-products:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}


.slider-products .product-informations {
  padding: 0 5% 5% 5%;
}

.slider-products .product-informations p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3em; /* Adjust based on your line-height */
}


.slider-categories p {
  font-size: 14px;
  margin-bottom: 2%;
  margin-bottom: 20%;
  text-align: center;
  font-weight: bold;
}

@media screen and (max-width: 1000px) {
  .slider-categories p {
    font-size: 13px;
    margin-bottom: 30%;
  }

  .slider-products {
    margin-bottom: 15%;
  }
}