.footer {
  background-color: #1a5276; /* Nice blue color */
  color: #fff;
  padding: 1rem 0;
}

/* Remaining CSS styles remain the same */
.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-logo a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.footer-links ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-links ul li {
  display: inline-block;
  margin-right: 1rem;
}

.footer-links ul li a {
  color: #fff;
  text-decoration: none;
}

.footer-bottom {
  text-align: center;
  margin-top: 1rem;
}
