.navbar {
  background-color: #1a5276; /* Nice blue color */
  color: white;
  padding: 1rem;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-logo a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.5rem;
}

.navbar-links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.navbar-links ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.navbar-links ul li {
  margin-right: 1rem;
}

.navbar-links ul li a {
  color: white;
  text-decoration: none;
}

.navbar-toggle {
  display: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .navbar-links {
    display: none;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #fff; /* Same blue color for mobile */
    z-index: 1000;
    flex-direction: column;
    padding: 1rem 0;
    color: black;
  }

  .navbar-links ul li a {
    color: black;
    text-decoration: none;
  }

  .navbar-links.active {
    display: flex;
  }

  .navbar-toggle {
    display: block;
  }
}
