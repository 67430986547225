.column-sideCategories-left {
  float: left;
  width: 25%;
}

.column-sideCategories-left p {
  cursor: pointer;
  margin-bottom: 1%;
}

.column-sideCategories-left p:hover {
  color: #1a5276;
}

.column-sideCategories-right {
  float: left;
  width: 75%;
}

@media screen and (max-width: 1000px) {
  .column-sideCategories-left {
    display: none;
  }

  .column-sideCategories-right {
    width: 90%;
    margin: 0 5% 0 5%;
  }
}