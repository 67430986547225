@import url('https://fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@400;500;600;700;800&display=swap');

/* Reset default browser styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Wix Madefor Display', sans-serif;
}

/* Global typography */
body {
  font-family: 'Wix Madefor Display', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  background-color: #f7f7f7;
}

a {
  text-decoration: none;
  color: black;
}

.global-container {
  width: 95%;
  margin-left: 2.5%;
  margin-right: 2.5%;
}

button {
  padding: 10px;
  background-color: white;
  color: black;
  border: 2px solid black;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  font-family: 'Wix Madefor Display', sans-serif;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.column-product {
  float: left;
  width: 23%;
  margin: 0 1%;
  text-align: left;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 5%;
  border-radius: 10px;
  background-color: white;
}

.column-product:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.column-product .product-informations {
  padding: 0 5% 5% 5%;
}

.column-product .product-informations p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3em; /* Adjust based on your line-height */
}

.column-product img {
  display: block;
  width: 90%;
  height: 200px;
  object-fit: contain;
  background-color: white;
  padding: 2%;
  border-radius: 10px;
  margin: 5% 0% 0% 5%;
}

@media screen and (max-width: 1000px) {
  .column-product {
    width: 98%;
    margin: 0 1%;
    margin-bottom: 50px;
  }

  .column-product img {
    height: 300px;
    padding: 2%;
  }
}

/* Table Style */
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

/* Table Header Style */
th {
  background-color: #f2f2f2;
  color: #333;
  font-weight: bold;
  text-align: left;
  padding: 8px;
  border-bottom: 2px solid #ddd;
}

/* Table Body Style */
td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

/* Alternating Row Color */
tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* Hover Effect */
tr:hover {
  background-color: #f5f5f5;
}

/* Images in tables */
table img {
  width: 50%;
  height: auto;
}

/* End Table Style */

/* Inputs */
input, select {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
}